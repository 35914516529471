.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}


.singins{
    width: 100%;
}


.fondo{
    padding-block: 5px;
    width: 100%;
    background-color: #3b5998
}

.fondo2{
    
    margin-top: 20px;
    padding-block: 5px;
    width: 100%;
    background-color: #00acee
}


  .boton{
    border: 2px solid #d0d0d0;
    width:50px;
    height:50px;

    margin: 5px;
    padding:10px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    font-size:11px;
    line-height:32px;
    text-transform: uppercase;
    float:left;
  }

  .botonfacebook{
    border: 2px solid #d0d0d0;
    width:50px;
    height:50px;
    background-color:#3b5998;
    margin: 5px;
    padding:10px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    font-size:11px;
    line-height:32px;
    text-transform: uppercase;
    float:left;
  }

  .left{

    padding-left: 10px;
  }

  .leftLabel{

    padding-left: 7px;
  }

  .crear-cuenta{

    margin-top: 5px;
    align-content: center;
  }

  .tamanoLetra{
    font-size:smaller;
  }

  .iconoRedes{
    margin: auto;
    display: block;
    width: 25px;
    height: 25px
  }
 


  
  