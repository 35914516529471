.contenedorImgMovil{
    background-color: #071b30;
    color: white;
}
.buttonsMirarMAs{
    margin-bottom: 10px;
}
.backimgpublishoffer{
    border-top-right-radius: 25% 50%;
    border-bottom-right-radius: 25% 50%;
    background-color: #FFC200;
    height: 400px;
    width: 100%;
    padding-right: 1em;
}
.imagpublishoffer{
    border-top-right-radius: 25% 50%;
    border-bottom-right-radius: 25% 50%;
    background-size: cover;
    background-image: url("../img/Mesatrabajo2.png");
    height: 100%;
    width: 100%;
    margin-right: 10px;
    overflow: hidden;
}
@supports(object-fit: cover){
    .imagpublishoffer img{
      /* height: 100%; */
      /* object-fit: cover; */
      object-position: center center;
    }
}
.orangeline{
    background-color: #FFC200;
    width: fit-content;
    color: white;
}
.negr{
    font-weight: bold;
    font-weight: 800;
    font-size: 3em;
}
.cardservice{
    background-color: #071b30;
    border-radius: 10%;
    height: 380px;
}
.orangelinelg{
    background-color: #FFC200;
    width: 100%;
    color: white;
}
.personImg{
    max-height: 600px;
}