body,
html {
  height: 100%;
  background-color: white;
}

.container {
  overflow: hidden;
}
.container .slider {
  animation: slidein 30s linear infinite;
  white-space: nowrap;
}
.container .slider .logos {
  width: 100%;
  display: inline-block;
  margin: 0px 0;
}
.container .slider .logos .fab {
  width: calc(100% / 5);
  animation: fade-in 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
}

.container .sliderimg {
  animation: slidein 40s linear infinite;
  white-space: nowrap;
}
.container .sliderimg .logosimg .fab {
  object-fit: cover;
  width: calc(100% / 5);
  animation: fade-in 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
}
.container .sliderimg .logosimg img {
  height: 350px;
}

@keyframes slidein {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}