body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  /* color: black; */
}

.lg-switch{
  height:2em !important;
  width: 4em !important;
}

/* #navbarSupportedContent ul li{
  width: 120px;
} */

.flaglang{
  position: absolute;
  top: 1px;
  right: 20px;
  z-index: 100;
  width: 50%;
}

.alingend{
  place-content: flex-end;
}

.backthitehome{
  background-color: white;
  width: 100%;
}

.cursorpointer{
  cursor: pointer;
}

.italic{
  font-style: italic;
}

.navitemwidth{
  width: max-content;
}