.gthanlight{
    font-size:large;
    font-weight: bold;
    font-family: "Gothan-light";
}

.contentHomeCoraAp{
    padding: 0px;
    /* height: 70vh; */
    /* background: url(../img/backgroundhome.png);
    background-size:auto;
    background-position: top;
    background-repeat: no-repeat;
    background-attachment: fixed; */
}

.coverwhite{
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    height: 100%;
}

.contentcenter{
    height: auto;
}

.conticons{
    /* width: 100%; */
    /* padding: 30px; */
    /* display: flex; */
    /* height: 220px; */
    /* align-items: center; */
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fec201;
}

.btn-white{
    background-color: white;
    padding: 10px;
    width: 50%;
    border-radius: 10px;
}

.btn-outline-darkcus{
    border: 1px solid #091D35;

}

.btn-outline-darkcus:hover{
    color:white;
    background-color: #091D35;
    /* border: 1px solid #ffffff; */
}

@media screen and (max-width: 765px){
    .imagen{
        display: none;
    }
    
    .carousel{
        display: block;
    }

}

@media screen and (min-width: 766px){
    .imagen{
        display: block;
    }
    
    .carousel{
        display: none;
    }
    
   

}