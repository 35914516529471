@font-face {
    font-family: "Gothan-Light";
    src: url("../Fonts/Gotham-Font/Gotham-Light.otf");
  }

@font-face {
    font-family: "Gothan-Bold";
    src: url("../Fonts/Gotham-Font/Gotham-Bold.otf");
}

@font-face {
  font-family: "Gothan-Black";
  src: url("../Fonts/Gotham-Font/Gotham-Black.otf");
}

@font-face {
  font-family: "Bebas_neue";
  src: url("../Fonts/bebas_neue/BebasNeue-Regular.otf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../Fonts/Montserrat/Montserrat-Light.ttf");
}

@font-face {
  font-family: "Montserrat_bold";
  src: url("../Fonts/Montserrat/Montserrat-Bold.ttf");
}

#root{
  position: relative;
}

.ocultarBtn{
  display: none;
}

.textbold{
    font-family: "Gothan-Bold"; 
}

.color-fuente-gris{

    color: gray;
    font-size: 15px;
}

.color-fuente-negro{
    color: rgb(0, 0, 0);
    font-size: 15px;
    cursor:pointer;
}

.imputText{
    border-radius: 10px;
    height:45px;
    font-family: "Gothan-Light";
}

.imputTextSelect{
  border-radius: 10px;
  height:45px;
  font-family: "Gothan-Light";
  font-size:13px;
}


.imputTextModal{
  border-radius: 10px;
  height:30px;
  font-family: "Gothan-Light";
  font-size: 12px;
}

.textArea{
  border-radius: 10px;
  height:150px;
  font-family: "Gothan-Light";
}

.buttons{
    color: floralwhite;
    width: 150px;
    height:40px;
    border-radius: 50px;
    font-family: "Gothan-Bold";
    font-size: 20px;
}

.buttonsUpdate{
  color: floralwhite;
  width: 100px;
  height:40px;
  border-radius: 50px;
  font-family: "Gothan-Bold";
  font-size: 15px;
}


.buttonsAdd{
  text-align: center;
  color: floralwhite;
  width: 35px;
  height:35px;
  border-radius: 50px;
  font-family: "Gothan-Bold";
  font-size: 16px;
}

.buttonsPortal{
  color: #FFC200;
  width: 280px;
  height:40px;
  border-radius: 50px;
  font-family: "Gothan-Bold";
  font-size: 20px;
  
}



.form-control:focus {
    border-color: #FFC200;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 238, 0, 0.623);
  }

  .form-select:focus {
    border-color: #FFC200;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 238, 0, 0.623);
  }



input:placeholder-shown {
    font-style: italic;
    font-family: "Gothan-Light";
 }

.inputCheckbox {
    font-family: "Gothan-BolD";
 }


 .checkbox-cerulean-blue-filled [type="checkbox"][class*='filled-in']:checked+label:after {
    border-color: #92aad0;
    background-color: #92aad0;
  }

  /*==============home===============**/

  #tipoTrabajo::placeholder {
    font-style: normal;
    font-family: "Gothan-Light";
  }

  .btn-primary {
    font-size: smaller;
    font-family: "Gothan-Bold";
    border-radius: 10px;
    color: #fff;
    background-color: #091D35;
    border-color: #091D35; /*set the color you want here*/
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  font-size: smaller;
  font-family: "Gothan-Bold";
  border-radius: 10px;  
  color: #fff;
    background-color: #071b30;
    border-color:#071b30; /*set the color you want here*/
}



.imputSelect{
  border-radius: 10px;
  height:45px;
  font-family: "Gothan-Light";
}

.botonBuscar{
  height: 45px;
}

.contenedorBuscar{
  height:200px ;
}

.bg-img-mesatrabajo{
  background: url(../img/Mesatrabajo2.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.contenedorImg{
  background: url(../img/imagen_buscar.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 400px;
}

.letreroWeb{
  font-style: normal;
  font-family: "Gothan-Bold";
  font-size: 40px;
  color: #071b30;
}

.letreroWebsmall{
  font-style: normal;
  font-family: "Gothan-Bold";
  font-size:x-large;
  color: #071b30;

}

.fontmont{
  font-family: "Montserrat";
}

.fontmontbold{
  font-family: "Montserrat_bold";
}

.fontbebas{
  font-family: "Bebas_neue";
}


.txtMediano{
  font-family: "Gothan-Bold";
  font-size:larger;
}

.txtPequeno{
  font-family: "Gothan-Bold";
  font-size: smaller;
}

.contenAmarillo{
  background-color:#FFC200;
}

.txtMedianoBlanco{
  font-family: "Gothan-Bold";
  font-size:x-large;
  color:#FFFFFF;
}

.txtPequenoBlanco{
  font-family: "Gothan-Light";
  font-size:larger;
  color:#FFFFFF;
}


.txtPequenoAzul{
  font-family: "Gothan-Light";
  font-size:large;
  color:#071b30;
}

.ImagenBody{
  width:100px;
  height:90px;
  float:left;
}


.personImg{
  position: bottom;
}



/*===========Fotter========*/

.contentFoteer{
  /* height: 100px; */
  background-color:#071b30;
}

.imputFooter{
  border-radius: 10px;
  background-color: #071b30;
  height:45px;
  font-family: "Gothan-Light";
  color: #FFFFFF;
 
}


/*===========About========*/

.contentAbout{
  height: 70px;
  background-color:#071b30;
}

.letreroCanada{
  font-style: normal;
  font-family: "Gothan-Bold";
  font-size: 60px;
  color: #071b30;

}

.vineta {
  color: #FFC200; /* bullet color */
  font-size: 35px;
   
}

.text{
  color: #071b30; /* text color */
  font-size: 18px;
  display: block;

}

.buttonsBuscartrabajo{
  color: floralwhite;
  width: 200px;
  height:40px;
  border-radius: 50px;
  font-family: "Gothan-Bold";
  font-size: 20px;
}

.centrador{
  text-align: center;
}


/*===========Mision Vision========*/

.txtMedianoAmarillo{
  font-family: "Gothan-Bold";
  font-size:larger;
  color:#FFC200;
}

.txtSmallAzul{
  text-decoration: none;
  font-family: "Gothan-Bold";
  font-size:14px;
  color:#071b30;
}

.txtSmallAzulModal{
  text-decoration: none;
  font-family: "Gothan-Bold";
  font-size:12px;
  color:#071b30;
}


.txtSmallAzulModalLeter{
  text-decoration: none;
  font-family: "Gothan-Bold";
  font-size:11px;
  color:#3a4552;
}

.txtnoRegister{
  text-decoration: none;
  font-family: "Gothan-Black";
  font-size:28px;
  color:#b6b6b6;
}

ul {
  list-style: none;
}

.vineta{
  list-style: none;
}

a:hover {color:#071b30;} 
.txtlargeAzul{
  text-decoration: none;
  font-family: "Gothan-Bold";
  font-size:16px;
  color:#071b30;
}


.txtSmallLightAzul{
  text-decoration: none;
  font-family: "Gothan-Light";
  font-size:large;
  color:#071b30;
}


/*===========Contact========*/
.fondoAmarillo{
  /* background-color : #FFC200; */
  background-color : #ff9d00;
  
}


.letreroWebBlnaco{
  font-style: normal;
  font-family: "Gothan-Bold";
  font-size: 25px;
  color: #ffffff;

}

.txtSmallLightBlanco{
  font-family: "Gothan-Light";
  font-size:larger;
  color:#ffffff;
}

.panel{

  height: 100px;
}

.imgform{
  /* max-width: 25%; */
  min-width: 150px;
  max-width: 400px;
  
}

.imgformcircle{
  width: 30%;
  height: 30%;
  
}

.msgerror{
  font-family: "Gothan-Light";
  font-size:small;
  color:red;
  
}



  /*==============ofertsJobs===============**/


  .filtrosfondo{

    background-color: rgb(170, 170, 170);
  }

  .filtrosopcioes{

    background-color: rgb(230, 230, 230);
  }


  .filtros{
    font-style: normal;
    font-family: "Gothan-Bold";
    font-size: 20px;
    color: #071b30;
  
  }


  .txtSmallGris{

    text-decoration: none;
    font-family: "Gothan-Light";
    font-size:12px;
    color:#071b30;
  }

  .txtSmallerGris{

    text-decoration: none;
    font-family: "Gothan-Light";
    font-size:16px;
    color:#071b30;
  }

  .txtSmallerGrisModal{

    text-decoration: none;
    font-family: "Gothan-Bold";
    font-size:20px;
    color:#071b30;
  }
 


  .sombra{
    box-shadow: 0px 0px 6px 1px rgba(65, 65, 65, 0.288);
  }


  .buttonsfcontent{
    color: floralwhite;
    width: fit-content;
    height:35px;
    border-radius: 50px;
    font-family: "Gothan-Bold";
    font-size: 15px;
  }


  .buttonsMirarMAs{
    color: floralwhite;
    width: 150px;
    height:35px;
    border-radius: 50px;
    font-family: "Gothan-Bold";
    font-size: 15px;
  }



  .buttonsMirarMAsModal{
    color: floralwhite;
    width: 100px;
    height:35px;
    border-radius: 50px;
    font-family: "Gothan-Bold";
    font-size: 15px;
  }

  .buttonsWarning{
    color: floralwhite;
    width: auto;
    border-radius: 50px;
    font-family: "Gothan-Bold";
    font-size: 15px;
  }


  .form-check-input:checked {
    background-color: #FF9A00;
    border-color: #FF9A00;
}


.fondo{
  background-color: #FF9A00;
}

.fondoi{
  background-color: #FF9A00 !important;
}


.funeteblanca{
  font-style: normal;
  font-family: "Gothan-Bold";
  font-size: 17px;
  color: #FFFFFF;

}

.funeteblancaModal{
  font-style: normal;
  font-family: "Gothan-Bold";
  font-size: 16px;
  color: #eed4d4;

}

.mostrar{
  display: none;
}


.buttonSmall{
  color: floralwhite;
  width: 130px;
  height:30px;
  border-radius: 50px;
  font-family: "Gothan-Bold";
  font-size: 12px;
  background-color: #FFC200;
  border-color:#FFC200
  
}

.buttonLarge{
  color: floralwhite;
  width: 180px;
  height:30px;
  border-radius: 50px;
  font-family: "Gothan-Bold";
  font-size: 12px;
  background-color: #FFC200;
  border-color:#FFC200
}


.alingDerecha{
  text-align: right;
}

.buttonSmallAzul{
  color: floralwhite;
  width: 180px;
  height:30px;
  border-radius: 5px;
  font-family: "Gothan-Bold";
  font-size: 12px;
  background-color: #071b30;
  border-color:#071b30;
  
}


.buttonSmallAzull{
  color: floralwhite;
  width: 200px;
  min-width: fit-content;
  height:30px;
  border-radius: 5px;
  font-family: "Gothan-Bold";
  font-size: 12px;
  background-color: #071b30;
  border-color:#071b30;
  
}

.buttonAzulModal{
  color: floralwhite;
  border-radius: 15px;
  font-family: "Gothan-Bold";
  font-size: 11px;
  background-color: #071b30;
  border-color:#071b30;
}

.buttonSmallAzulModal{
  color: floralwhite;
  width: 180px;
  height:30px;
  border-radius: 15px;
  font-family: "Gothan-Bold";
  font-size: 11px;
  background-color: #071b30;
  border-color:#071b30;
}

.btnfloatcomments{
  color: floralwhite;
  width: fit-content;
  border-radius: 50%;
  padding: 20px;
  background-color: #071b30;
  border-color:#071b30;
}


.buttonSmallNaranja{
  color: floralwhite;
  width: 180px;
  height:30px;
  border-radius: 5px;
  font-family: "Gothan-Bold";
  font-size: 12px;
  background-color: #FF9A00;
  border-color:#FF9A00;
}


.btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active, .open>.dropdown-toggle.btn-light {
  font-size: 12px;
  font-family: "Gothan-Bold";
  border-radius: 5px;  
  color: #fff;
    background-color: #071b30;
    border-color:#071b30; /*set the color you want here*/
}


.btn-lightModal:hover, .btn-lightModal:focus, .btn-lightModal:active, .btn-lightModal.active, .open>.dropdown-toggle.btn-lightModal {
  font-size: 11px;
  font-family: "Gothan-Bold";
  border-radius: 15px;  
  color: #fff;
    background-color: #071b30;
    border-color:#071b30; /*set the color you want here*/
}

.btn-naranja:hover, .btn-naranja:focus, .btn-naranja:active, .btn-naranja.active, .open>.dropdown-toggle.btn-naranja {
  font-size: 12px;
  font-family: "Gothan-Bold";
  border-radius: 5px;  
  color: #fff;
    background-color: #FF9A00;
    border-color:#FF9A00; /*set the color you want here*/
}


/*comentarios */
.fondoAzulModal{
  border-radius: 15px;  
  background-color: #091D35;
  color: #fff;
  font-size: 12px;
  font-family: "Gothan-Bold";
}

.derecha{
  text-align: right,  
}

.fondoBlancoModal{
  background-color: #fff;
  color: #091D35;
  font-size: 12px;
  font-family: "Gothan-Bold";
  width: 420px;
  height: 270px;

}

.linea{
  background-color: #FF9A00;
  height: 5px ;
}


.anyClass {
  height:270px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

}

/*chat */
.globo{
  background-color:#ffffff;
border:1px solid #c9c9c9;

-moz-border-radius:10px;
-webkit-border-radius:10px;

}

/*paginacion*/
.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination > .active > a {

  background-color: #091D35;
  border-color: #091D35;
  color: #fff;
  border-radius: 15px;


}
.pagination > li > a {
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #091D35;
  border-color: #091D35;
  outline: none;
  color: #FFFFFF;
}
.pagination > li > a,
.pagination > li > span {
  color: #091D35;
  text-decoration:none;
  font-family: "Gothan-Bold";
  font-size: 16px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}


.modalazul{
  background-color:#1E253F;
}

.modalheader{
	background-color:#071b30;
}

.colormodal{
  text-decoration: none;
      font-size:15px;
    color:#FFFFFF;
}

@media (max-width: 500px) {
  .color {

      width: 160px
  }

  .titulopading{

    margin-top:70px;
  }

  .btnBuscar{
      width:100%
  }

  .person{
    display:none;
  }

  .imgmov{
    display:none;
  }


  .textmov{
    width:100%;
    font-size:30px;
  }

  .text-movil{
    display: none;
  }


  .fondoBlancoModalMovil{

    background-color: #fff;
    color: #091D35;
    font-size: 12px;
    font-family: "Gothan-Bold";
    width: 300px;
    height: 250px;
  
  }

  .anyClassMovil {
    height:250px;
    overflow-y: scroll;
    overflow-x: hidden;
    
    
  }

  .ingMpvilchat{
    width:30px;
    height:30px;
  }

  .lineamovil{

    background-color: #FF9A00;
    height: 5px ;
  
  }
 


  .dropdown-menu {
    width: 300px !important;
    height: 400px !important;
}

  .marginAmarillo{
    scroll-padding-top: 20px;
  }

  .textmovil{
    font-size:12px
  }

  .icoOpciones{
    widows: 20px;
    height: 20px;
    text-align: center;
  }


  .ocultarMovil{
    display: none;
  }
  .ocultar{
    display: none;
  }
  .mostrar{
    display: block;
  }

  
}

.floatcomments{
  position: fixed;
  top: 85%;
  right: 5%;
  z-index: 9;
}

.btnhpage{
  /* width: 80%; */
  border-radius: 50px;
  /* border: 1px solid #091D35; */
  background: #091D35;
  color: white;
}
.btnhpage:hover {
  background: white;
  color: #091D35 !important;
}

.heigthtarget{
  height: 150px;
}

.widtauto{
  width: auto;
}

.margin-left{
  margin-left: 25%;
}
